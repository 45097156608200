import gsap from 'gsap';
import parse from 'html-react-parser';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

// import analytics from '@lib/analytics';
import TrackedLink from '@components/TrackedLink';

export default function Offers({ offerings, cta, links, regular_price }) {
	const { t } = useTranslation('common');

	useEffect(() => {
		const q = gsap.utils.selector('.REWARDS');

		gsap.timeline()

			.fromTo(
				q('.OFFERINGS'),
				{ autoAlpha: 0, y: 20 },
				{ immediateRender: true, duration: 1.75, autoAlpha: 1, y: 0, stagger: 0.15, ease: 'expo.out' },
				1
			)
			.timeScale(1.5);
	}, []);

	return (
		<section className={`OFFERINGS relative -top-12 z-50`}>
			<div className='flex flex-col items-center justify-center gap-4 ml-10 mr-10 md:justify-start sm:flex-row md:ml-24'>
				{offerings.map((v, i) => {
					return (
						<article key={v.id} className={`card bg-white rounded-[10px] w-full sm:w-80 sm:h-[18rem] relative`}>
							<div
								className={`card-body outline-[5px] pt-20 text-center flex ${
									v.saleTagline ? '__outline-blue_bg' : ''
								} rounded-[6px] relative overflow-hidden `}
							>
								<h2
									className={`font-bold font-trade_gothic_next_heavy_condensed text-2xl bg-slate-200  text-black leading-none absolute top-0 p-4 w-full left-0 text-center`}
								>
									{t(parse(v.title))}
								</h2>

								<div className='mb-2 text-sm font-bold text-black font-trade_gothic_next_condensed'>
									{v?.price_sale && (
										<p
											className={`font-bold text-sm mt-2 text-gray-400 font-trade_gothic_next_condensed leading-none`}
										>
											{t(parse(v.regularPrice))}{' '}
											<strike className='text-black'>{parse(v.price_sale)}</strike>
										</p>
									)}
								</div>

								{v?.installments && (
									<p className='p-0 text-sm font-bold text-gray-400 font-trade_gothic_next_condensed'>
										{t(parse(parse(v.installments)))}
									</p>
								)}

								{v.saleTagline && (
									<aside className='w-full font-trade_gothic_next_heavy_condensed uppercase font-bold z-50 tracking-wider bg-blue_bar text-dark_blue py-0 md:py-1 absolute top-[32px] left-[40px] text-center -translate-x-1/2 -rotate-[45deg] pointer-events-none'>
										{v.saleTagline}
									</aside>
								)}

								<div className='mt-auto'>
									{v?.price && (
										<strong
											className={`font-trade_gothic_next_heavy_condensed text-6xl  text-blue_bar leading-none mb-5 block`}
										>
											{parse(v.price)}
										</strong>
									)}

									<TrackedLink
										href={cta?.link || links[cta.id]}
										target='_blank'
										event={{ event: `Subscribe Click`, section: 'Main' }}
										className='CTA CTA-SUBSCRIBER btn _btn font-trade_gothic_next_heavy_condensed !w-full hover:!bg-dark_blue hover:!text-white'
									>
										<span className={``}>{t(cta.label)}</span>
									</TrackedLink>
								</div>
							</div>
						</article>
					);
				})}
			</div>
		</section>
	);
}
