import gsap from 'gsap';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useEffect } from 'react';

export default function Watch({ title, title_m, icon, logos }) {
	const { t } = useTranslation('common');

	useEffect(() => {
		const q = gsap.utils.selector('.WATCH');

		gsap.timeline()
			.fromTo(
				[q('.TITLE')].concat(q('.ITEM')),
				{ autoAlpha: 0, y: 20 },
				{ duration: 0.5, autoAlpha: 1, y: 0, stagger: 0.125, ease: 'expo.out' },
				1.5
			)
			.progress(1);
	}, []);

	return (
		<section className={`WATCH hero bg-white p-24 px-12 relative md:p-24`}>
			<div className={`LOCKUP w-full gap-2 text-[2.5rem] lg:text-[3.5rem]`}>
				<figure className={`w-16 mx-auto -mt-10 sm:w-24`}>
					<Image className={`w-full`} src={icon?.src} width={icon?.width} height={icon?.height} alt={icon?.label} />
				</figure>

				<h1 className='TITLE opacity-0 w-full flex items-center justify-center font-bold text-[0.625em] text-center leading-[1.1] text-black mx-auto mt-4 mb-6 relative z-[1] sm:sm:max-w-[85%] lg:mb-8'>
					<span className={`hidden md:inline`}>
						<span dangerouslySetInnerHTML={{ __html: t('watchTitle') }} />{' '}
					</span>
					<span className={`inline text-[1.25em] md:hidden`}>
						{' '}
						<span dangerouslySetInnerHTML={{ __html: t('watchTitleMobile') }} />{' '}
					</span>
				</h1>

				<div className='LOGO-ITEMS flex flex-wrap items-center justify-center px-4 gap-0 md:gap-2 mx-auto sm:grid sm:grid-flow-col scale-[1.2] sm:max-w-[1000px] sm:auto-cols-fr sm:scale-100 sm:px-0 mt-14'>
					{logos.map((v, i) => {
						let className = '';

						switch (v.id) {
							case 'androidtv':
								className += 'scale-[1.2]';
								break;
							case 'android':
								className += ' sm:!h-[90px]';
								break;
							case 'androidtv':
								className += ' sm:!h-[90px]';
								break;
							case 'vizio':
								className += ' sm:!h-[90px]';
								break;
							case 'lg':
								className += ' sm:!h-[90px]';
								break;
							case 'ios':
								className += '-right-1 scale-[1.45] mt-[3px] sm:!w-[120px] sm:!h-[90px] sm:-right-3';
								break;
							case 'atv':
								className += '!h-[80px] p-[4px] sm:p-0';
								break;
							case 'firetv':
								className += 'sm:!h-[90px]';
								break;
							case 'roku':
								className += 'sm:!h-[80px]';
								break;
							case 'samsung':
								className += 'sm:!w-[150px] sm:!h-[120px]';
								break;
						}

						return (
							<figure key={v.src} className={`LOGO w-1/3 -my-2.5 relative sm:w-full lg:my-0`}>
								<Image
									className={`w-full object-contain relative sm:w-[133px] sm:h-[100px] ${className}`}
									src={v.src}
									width={v.width}
									height={v.height}
									alt={v.label}
								/>
							</figure>
						);
					})}
				</div>
			</div>
		</section>
	);
}
