import TrackedLink from '@components/TrackedLink';
import gsap from 'gsap';
import parse from 'html-react-parser';
import is from 'is_js';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useEffect, useRef } from 'react';
import { useWindowSize } from 'react-use';
import Offers from './Offers';

// import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
// import analytics from '@lib/Analytics';

export default function Main({
	logo,
	links,
	// title,
	// title_m,
	video,
	video_poster,
	video_m,
	video_m_poster,
	// subtitle,
	section_title,
	section_subtitle,
	bgImage,
	image_app,
	logos,
	cta,
	cta1,
	cta3,
	cta4,
	offerings,
}) {
	// const router = useRouter();
	const { t } = useTranslation('common');

	const { width } = useWindowSize();
	const isIos = is.ios();

	const videoDesktopRef = useRef();
	const videoPhoneRef = useRef();

	useEffect(() => {
		const q = gsap.utils.selector('.MAIN-HERO');

		gsap.timeline()
			.fromTo(
				[q('.MAIN-LOGO')],
				{ autoAlpha: 1, x: '-140%' },
				{ immediateRender: true, duration: 1.25, autoAlpha: 1, x: 0, stagger: 0.125, ease: 'expo.out' },
				0.75
			)
			.fromTo(
				[q('.TITLE'), q('.BLURB'), q('.STRIPES')],
				{ autoAlpha: 0, y: 20 },
				{ immediateRender: true, duration: 0.75, autoAlpha: 1, y: 0, stagger: 0.15, ease: 'expo.out' },
				1
			)
			.fromTo(
				q('.LOGO-WRAPPER'),
				{ scale: 0 },
				{ immediateRender: true, duration: 1, scale: 1, stagger: 0.15, ease: 'expo.out' },
				'-=0.75'
			)
			.fromTo(
				[q('.CTA')].concat(q('.CTA-LEGAL')),
				{ autoAlpha: 0, y: 20 },
				{ immediateRender: true, duration: 0.75, autoAlpha: 1, y: 0, stagger: 0.15, ease: 'expo.out' },
				'-=0.75'
			)
			.timeScale(1.5);
	}, []);

	const onVideoSuspend = (e) => {
		let target;

		if (e.target.dataset.id === 'desktop') {
			target = videoDesktopRef.current;
		} else {
			target = videoPhoneRef.current;
		}

		gsap.to(target, { duration: 1, opacity: 1, ease: 'expo.out' });
	};

	const onVideoReady = (e, _target) => {
		try {
			let target = _target;

			if (!target && e.target.dataset.id === 'desktop') {
				target = videoDesktopRef.current;
			} else if (!target) {
				target = videoPhoneRef.current;
			}

			gsap.to(target, { duration: 1, opacity: 1, ease: 'expo.out' });
		} catch (err) {
			console.log('video play failed');
		}
	};

	useEffect(() => {
		if (width >= 768) {
			videoDesktopRef.current
				.play()
				.then(() => {})
				.catch((error) => {
					if (isIos && error.name === 'NotAllowedError') {
						onVideoReady(null, videoDesktopRef.current);
					}
				});

			videoPhoneRef.current.pause();
		} else {
			videoDesktopRef.current.pause();

			videoPhoneRef.current
				.play()
				.then(() => {})
				.catch((error) => {
					if (isIos && error.name === 'NotAllowedError') {
						onVideoReady(null, videoPhoneRef.current);
					}
				});
		}
	}, [isIos, width]);

	useEffect(() => {
		const handler = () => {
			const desktop_videoPlaying = !!(
				videoDesktopRef.current.currentTime > 0 &&
				!videoDesktopRef.current.paused &&
				!videoDesktopRef.current.ended &&
				videoDesktopRef.current.readyState > 2
			);
			const phone_videoPlaying = !!(
				videoPhoneRef.current.currentTime > 0 &&
				!videoPhoneRef.current.paused &&
				!videoPhoneRef.current.ended &&
				videoPhoneRef.current.readyState > 2
			);

			if (width >= 768) {
				try {
					// videoDesktopRef.current.play();
					// videoPhoneRef.current.pause();

					if (!desktop_videoPlaying) {
						videoDesktopRef.current
							.play()
							.then(() => {})
							.catch((error) => {});
					}

					if (phone_videoPlaying) {
						videoPhoneRef.current.pause();
					}
				} catch (err) {}
			} else {
				try {
					// videoDesktopRef.current.pause();
					// videoPhoneRef.current.play();

					if (desktop_videoPlaying) {
						videoDesktopRef.current.pause();
					}

					if (!phone_videoPlaying) {
						videoPhoneRef.current
							.play()
							.then(() => {})
							.catch((error) => {});
					}
				} catch (err) {}
			}
		};

		document.body.addEventListener('click', handler);
		document.body.addEventListener('touchstart', handler);

		return () => {
			document.body.removeEventListener('click', handler);
			document.body.removeEventListener('touchstart', handler);
		};
	}, [width]);

	return (
		<>
			<section
				className={`MAIN-HERO hero items-start justify-start min-h-[650px] bg-base-100 px-6 relative md:items-center md:px-24`}
			>
				<figure
					className={`absolute inset-0 bg-black -ml-6  after:absolute after:inset-0 after:bg-gradient-to-r after:from-black/90 after:via-black/50 __after:opacity-[0.6] md:-mx-24`}
				>
					{video && (
						<video
							ref={videoDesktopRef}
							data-id='desktop'
							className={`hidden w-full h-full object-cover __grayscale opacity-0 md:block`}
							src={video}
							poster={video_poster}
							preload={is.desktop() && is.not.safari() ? 'metadata' : 'none'}
							playsInline
							loop
							muted
							onPlaying={onVideoReady}
						/>
					)}

					{video_m && (
						<video
							ref={videoPhoneRef}
							data-id='mobile'
							className={`block w-full h-full object-cover __grayscale opacity-0 md:hidden`}
							src={video_m}
							poster={video_m_poster}
							preload={is.desktop() && is.not.safari() ? 'metadata' : 'none'}
							playsInline
							loop
							muted
							onPlaying={onVideoReady}
						/>
					)}

					<div className='absolute inset-0 opacity-50 bg-dotted-spacing-4 bg-dotted-blue_bar' />
				</figure>

				<TrackedLink
					href={links.yesNetwork}
					target='_blank'
					event={{ event: `Yes Logo Click`, section: 'Hero' }}
					className={`MAIN-LOGO w-[130px] __mt-[44px] absolute top-8 left-0 md:w-[80px] md:-left-16`}
				>
					<Image className={`w-full h-auto`} width={logo.width} height={logo.height} src={logo.src} alt={logo.label} />
				</TrackedLink>

				<TrackedLink
					href={cta1?.link || links[cta1.id]}
					target='_blank'
					event={{ event: `Login Link Click`, section: 'Hero' }}
					className='CTA CTA-LOGIN opacity-0 _link text-right !no-underline cursor-default absolute top-8 right-8 w-[200px] md:w-full'
				>
					{cta1?.prelabel && (
						<span className={`_link !no-underline mr-1 pointer-events-none cursor-default`}>{t(cta1?.prelabel)}</span>
					)}
					<div className={`text-underline !cursor-pointer !text-lg font-bold`}>{t(cta1.label)}</div>
				</TrackedLink>

				<div className='absolute left-0 text-left opacity-0 md:text-right CTA-LEGAL md:left-auto md:right-12 bottom-24 lg:bottom-16'>
					<TrackedLink
						href={cta3?.link || links[cta3.id]}
						target='_blank'
						event={{ event: `Legal Link Click`, section: 'Hero' }}
						className='trade_gothic_next_condensed'
					>
						<span className={`_link`}>{t(cta3.label)}</span>
					</TrackedLink>
					<span className='block text-xs text-white trade_gothic_next_condensed'>{t(parse(cta4?.label))}</span>
				</div>

				<div className='p-0 mt-48 text-left hero-content md:mt-0'>
					<div className={`LOCKUP grid grid-flow-row gap-8 text-[2.5rem] lg:text-[3.5rem]`}>
						<h1 className='font-bold leading-none opacity-0 TITLE text-primary'>
							<span className={`hidden md:inline`}>
								{' '}
								<span dangerouslySetInnerHTML={{ __html: t("title") }} />{' '}
							</span>
							<span className={`inline md:hidden`}>
								{' '}
								<span dangerouslySetInnerHTML={{ __html: t("titleMobile") }} />{' '}
							</span>
						</h1>

						<div className='BLURB opacity-0 block font-medium text-white text-[0.65em] tracking-wide leading-none -mt-3 relative'>
							{t(parse("subtitle"))}

							<figure className={`STRIPES absolute -left-24 top-0 mt-12 lg:mt-[72px]`}>
								<Image
									className={`hidden max-w-none md:block w-[585px] lg:w-[776px]`}
									src={bgImage.src}
									width={bgImage.width}
									height={bgImage.height}
									alt=''
									priority={width >= 1024}
								/>
							</figure>
						</div>

						<div className='flex items-center justify-start gap-6 LOGOS lg:gap-8'>
							{logos.map((v, i) => {
								return (
									<figure key={v.src} className={`LOGO-WRAPPER relative`}>
										<Image
											className={`LOGO min-w-[43px] min-h-[55px] lg:min-w-[50px] lg:min-h-[50px] ${
												v.id === 'nets' ? '!min-w-[55px] lg:!min-h-[60px]' : ''
											} ${v.id === 'liberty' ? '!min-w-[45px] -mt-1.5 lg:!min-h-[61px]' : ''}`}
											src={v.src}
											width={v.width}
											height={v.height}
											alt={v.label}
										/>
									</figure>
								);
							})}
						</div>
					</div>
				</div>
			</section>

			<section
				className={`REWARDS bg-[url('/assets/images/main/bg.jpeg')] md:h-[1000px] w-full bg-cover bg-center relative`}
			>
				<Offers offerings={offerings} cta={cta} links={links} />
				<div className='relative w-full xl:-top-60 md:flex'>
					<div className='flex items-center justify-center w-full p-10'>
						<div>
							<h1 className='mb-4 text-6xl font-bold leading-none text-right uppercase md:text-right TITLE font-integral_heavy'>
								<span>
									<span dangerouslySetInnerHTML={{ __html: t(section_title) }} />{' '}
									{/* <span className='text-white'>The Best Way</span> <br /><span className='text-blue_bar'>To Watch</span> */}
									
								</span>
							</h1>

							<div className='text-lg font-medium leading-6 tracking-wide text-right text-white w-58 BLURB font-trade_gothic_next_condensed'>
								<span dangerouslySetInnerHTML={{ __html: t(section_subtitle) }} />{' '}
							</div>
						</div>
					</div>
					<div className='relative flex items-center justify-center w-full p-10'>
						<figure className='-mb-20'>
							<Image  src={image_app.src} width={image_app.width} height={image_app.height} alt='App' />
						</figure>
					</div>
				</div>
			</section>
		</>
	);
}
