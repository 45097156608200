import { useTranslation } from 'next-i18next';
import Image from 'next/image';

// import analytics from '@lib/analytics';

export default function WatchPlay({ image_fan, image_app }) {
	const { t } = useTranslation('common');
	// const router = useRouter();

	// useEffect(() => {
	// 	const q = gsap.utils.selector('.WATCHPLAY');

	// 	gsap.timeline()

	// 		.fromTo(
	// 			[q('.TITLE'), q('.BLURB')],
	// 			{ autoAlpha: 0, y: 20 },
	// 			{ immediateRender: true, duration: 1.75, autoAlpha: 1, y: 0, stagger: 0.15, ease: 'expo.out' },
	// 			1
	// 		)
	// 		.timeScale(1.5);
	// }, []);

	return (
		<section
			className={`WATCHPLAY bg-[url('/assets/images/watchplay/bg.jpeg')]  lg:h-[1000px] w-full bg-cover bg-center relative overflow-hidden`}
		>
			<div className='relative'>
				<div className='flex flex-col-reverse lg:flex-row'>
					<div className='relative flex items-center justify-center w-full h-auto px-12'>
						<figure className='mt-0 lg:-mt-16'>
							<Image
								className='max-w-[100%] md:max-w-[560px]'
								src={image_app.src}
								width={image_app.width}
								height={image_app.height}
								alt='App'
							/>
						</figure>
					</div>
					<div className='flex items-start justify-center w-full h-auto'>
						<div className='text-left p-14 lg:pt-24 md:pr-24 lg:mt-48 mt:20'>
							<h1 className='mb-4 text-6xl font-bold leading-none uppercase TITLE font-integral_heavy'>
								<span>
									<span dangerouslySetInnerHTML={{ __html: t('watchPlayTitle') }} />{' '}
								</span>
							</h1>

							<div className='text-lg font-medium leading-6 tracking-wide text-dark_blue BLURB font-trade_gothic_next_condensed'>
								{t(
									'Make gameday more fun by making picks before, or play along during games where you can win BIG cash prizes.'
								)}
							</div>
						</div>
					</div>
				</div>

				<div className='px-12 mt-10 mb-10 lg:mb-0 lg:w-[800px] -bottom-0 lg:left-1/2 transform lg:-translate-x-1/2 lg:absolute ml-auto mr-auto '>
					<figure className='lg:max-w-[800px]'>
						<Image src={image_fan.src} width={image_fan.width} height={image_fan.height} alt='Phone' />
					</figure>
				</div>
			</div>
		</section>
	);
}
