import gsap from 'gsap';
import parse from 'html-react-parser';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

// import analytics from '@lib/analytics';
import TrackedLink from '@components/TrackedLink';

export default function FAQ({ logo, links, title, title_m, questions, cta, footer }) {
	const router = useRouter();
	const { t } = useTranslation('common');

	const toggleQuestion = (evt, idx) => {
		const target = evt.currentTarget;
		const answer = target.querySelector('.__collapse-content');
		const plus = target.querySelector('.TOGGLE-ICON .PLUS');
		const minus = target.querySelector('.TOGGLE-ICON .MINUS');

		const parent = target.parentNode;
		const siblings = parent.querySelectorAll('.QUESTION');

		[...siblings].forEach((v) => {
			if (v === target) {
				if (target.dataset.open === 'true') {
					target.dataset.open = false;
					gsap.to(answer, { duration: 0.5, maxHeight: 0, ease: 'expo.out' });
					plus.classList.remove('!hidden');
					minus.classList.add('!hidden');
				} else {
					target.dataset.open = true;
					gsap.to(answer, { duration: 0.5, maxHeight: answer.scrollHeight, ease: 'expo.out' });
					plus.classList.add('!hidden');
					minus.classList.remove('!hidden');
				}
			} else {
				let _answer = v.querySelector('.__collapse-content');
				let _plus = v.querySelector('.TOGGLE-ICON .PLUS');
				let _minus = v.querySelector('.TOGGLE-ICON .MINUS');

				//console.log('SIBLING (NO MATCH)');

				v.dataset.open = false;
				gsap.to(_answer, { duration: 0.5, maxHeight: 0, ease: 'expo.out' });
				_plus.classList.remove('!hidden');
				_minus.classList.add('!hidden');
			}
		});
	};

	useEffect(() => {
		const q = gsap.utils.selector('.FAQ');

		gsap.timeline()
			.fromTo(
				[q('.TITLE')].concat(q('.QUESTION')),
				{ autoAlpha: 0, y: 20 },
				{ duration: 0.5, autoAlpha: 1, y: 0, stagger: 0.125, ease: 'expo.out' },
				1.5
			)
			.progress(1);
	}, []);

	const backgroundStyle = router.pathname === '/nets' ? '!bg-[#111318]' : 'bg-base-100';

	return (
		<section className={`FAQ hero p-24 px-6 relative md:p-24 ${backgroundStyle}`}>
			<div className={`LOCKUP w-full gap-2 text-[2.5rem] lg:text-[3.5rem]`}>
				<h1 className='TITLE opacity-0 w-full flex items-center justify-center font-bold text-[0.8em] text-center leading-[1.1] text-primary mt-4 mb-8 lg:mb-12 lg:text-[0.75em]'>
					{t(parse(title))}
				</h1>

				<div className='QUESTIONS-ITEMS max-w-[800px] mx-auto'>
					{questions.map((v, i) => {
						// TODO
						const answerParsed = v.answer;

						return (
							<button
								key={v.question}
								className={`QUESTION __collapse __collapse-plus w-full flex flex-wrap items-center justify-between border-b border-primary/75 font-medium text-xl text-primary relative last:mb-0`}
								onClick={(e) => toggleQuestion(e, i)}
							>
								<h3
									className={`collapse-title pr-8 flex items-center justify-between text-[1em] text-left pl-0 relative pointer-events-none after:text-[0.85em] after:text-blue_bar after:!top-1/4 after:!right-0.5`}
								>
									{t(parse(v.question))}

									<span className={`TOGGLE-ICON text-blue_bar absolute top-1/2 right-0 -translate-y-1/2`}>
										<span className={`PLUS inline-block plus`}>+</span>
										<span className={`MINUS inline-block relative right-[3px] scale-[1.5] !hidden`}>-</span>
									</span>
								</h3>

								<div className={`__collapse-content max-h-0 transform-gpu w-full text-left p-0 overflow-hidden`}>
									<div className={`text-blue_bar pb-4`}>
										{' '}
										<span dangerouslySetInnerHTML={{ __html: t(answerParsed) }} />{' '}
									</div>
								</div>
							</button>
						);
					})}
				</div>

				<div className={`FAQS-FOOTER mt-16`}>
					<TrackedLink
						href={cta?.link || links[cta.id]}
						target='_blank'
						event={{ event: `Subscribe Click`, section: 'FAQs' }}
						className='CTA CTA-SUBSCRIBER flex __opacity-0 w-fit btn _btn -mt-1.5 mx-auto mb-10'
					>
						<span className={``}>{t(cta.label)}</span>
					</TrackedLink>

					<h2 className='TITLE FOOTER-TITLE opacity-0 w-full flex items-center justify-center font-medium text-[0.4em] text-center leading-[1.1] text-primary mb-4  lg:text-[0.375em]'>
						{t(parse(footer.title))}
						<TrackedLink
							href={links[footer?.titleLink?.id]}
							target='_blank'
							event={{ event: `Help Center Link Click`, section: 'FAQs' }}
							className={`_link !font-medium !text-[1em] ml-2`}
						>
							{t(parse(footer?.titleLink?.label))}
						</TrackedLink>
					</h2>

					<ul className={`flex items-center justify-center gap-4 p-0 m-0`}>
						{footer.links.map((v) => {
							return (
								<li key={v.id + v.label} className={`inline-flex p-0 m-0 leading-normal`}>
									<TrackedLink
										href={links[v.id]}
										target='_blank'
										event={{ event: `Legal Link Click`, section: 'FAQs' }}
										className={`_link !text-sm`}
									>
										{t(parse(v.label))}
									</TrackedLink>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</section>
	);
}
