import data from '@data/index';
import { useTranslation } from 'next-i18next';

export default function Footer() {
	const { t } = useTranslation('common');
	return (
		<footer className='relative items-center px-6 py-2 text-white footer bg-blue_bar'>
			<div className={`flex flex-wrap w-full justify-center text-[9px] text-center pr-2.5 gap-0 md:justify-end md:mt-0`}>
				<p className={`w-full font-sans text-center __md:text-right`}>&copy; {t(data.global.copyright)}</p>
			</div>
		</footer>
	);
}
