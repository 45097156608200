import { useEffect } from 'react';
import gsap from 'gsap';

import Footer from '@components/Footer';

import Main from '@components/Main';

import Reasons from '@components/Reasons';
import Rewards from '@components/Rewards';
import WatchPlay from '@components/WatchPlay';

import Watch from '@components/Watch';
import FAQ from '@components/FAQ';

import data from '@data/index';

export default function Index() {
	const { global, main, reasons, rewards, watchplay, watch, faq } = data;

	useEffect(() => {
		gsap.timeline().to('body', { duration: 0, autoAlpha: 1, ease: 'expo.out' }, 0);
		//.progress(1)
	}, []);

	return (
		<>
			<main className='font-sans min-h-[calc(100vh-44px)] bg-base-100'>
				<Main logo={global.logo} links={global.links} {...main} />

				<WatchPlay {...watchplay} />

				<Rewards {...rewards} />

				<Reasons logo={global.logo_dark} links={global.links} {...reasons} />

				<Watch logo={global.logo} links={global.links} {...watch} />

				<FAQ logo={global.logo} links={global.links} {...faq} />
			</main>

			<Footer />
		</>
	);
}

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

export async function getStaticProps({ locale }) {
	return {
		props: {
			...(await serverSideTranslations(locale, 'common')),
		},
	};
}
