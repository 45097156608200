import { useTranslation } from 'next-i18next';
import Image from 'next/image';

// import ScrollTrigger from 'gsap/ScrollTrigger';

// import analytics from '@lib/analytics';

export default function Packages({ logo, title, list, image_players }) {
	const { t } = useTranslation('common');
	// useEffect(() => {
	//   const q = gsap.utils.selector('.REASONS');

	//   gsap.timeline({
	//     scrollTrigger: {
	//       trigger: '.REASONS',
	//       start: 'top center', // Animation starts when the top of '.REASONS' hits the center of the viewport
	//       end: 'bottom center', // Adjust as needed
	//       scrub: true, // Smooth scrubbing, adjust or remove as needed
	//     },
	//   })
	//   .fromTo(
	//     q('.IMAGE'),
	//     { autoAlpha: 0, y: 20 },
	//     {
	//       duration: 0.5,
	//       autoAlpha: 1,
	//       y: 0,
	//       stagger: 0.125,
	//       ease: 'expo.out',
	//     }
	//   );

	// }, []);

	return (
		<section
			className={`REASONS md:mx-6 pt-12 pl-12 pr-12 md:pt-24 md:pb-0 md:pl-24 md:pr-24 relative bg-white md:rounded-t-3xl shadow-4xl overflow-hidden`}
		>
			<div className='w-full p-0 text-center'>
				<div className={`LOCKUP w-full grid grid-flow-row gap-6 text-[2.5rem] lg:text-[3.5rem]`}>
					<h1 className='TITLE font-integral_heavy flex items-center justify-center w-full uppercase font-bold text-[0.4em] md:text-[0.6em] text-primary 	  lg:mt-4'>
						<span dangerouslySetInnerHTML={{ __html: t('reasonsTitle') }} />
						<figure className={`w-14 md:w-24 ml-4`}>
							<Image
								className={`w-full h-auto inline-block`}
								width={logo.width}
								height={logo.height}
								src={logo.src}
								alt={logo.label}
							/>
						</figure>
					</h1>

					{list && (
						<div className='text-center sm:mb-22 md:mb-72 md:ml-auto md:mr-auto lg:mb-96 xl:mb-[440px]'>
							<ul className='LIST grid grid-col-1 w-full md:grid-cols-2 gap-3 list-none text-[0.5em] text-dark_blue mt-4 text-left'>
								{list.map((v, i) => (
									<li
										key={i}
										className='px-6 py-2 text-[1.1rem] font-medium list-none bg-gray-100 border rounded-md border-accent w-full'
									>
										{t(v)}
									</li>
								))}
							</ul>
						</div>
					)}

					<figure className={`IMAGE relative w-full z-50`}>
						<Image
							className={`w-full md:-mb-10 md:absolute md:bottom-0 xs:max-w-[400px] md:max-w-[1200px] lg:max-w-[1050px] md:left-1/2 transform md:-translate-x-1/2`}
							width={image_players.width}
							height={image_players.height}
							src={image_players.src}
							alt={image_players.label}
						/>
					</figure>
				</div>
			</div>
		</section>
	);
}
