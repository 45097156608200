import { useEffect } from 'react';
import Link from 'next/link';
import parse from 'html-react-parser';
import gsap from 'gsap';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

// import analytics from '@lib/analytics';
import TrackedLink from '@components/TrackedLink';

export default function Rewards({ image_fan, image_app }) {
	const { t } = useTranslation('common');
	// const router = useRouter();

	// useEffect(() => {
	// 	const q = gsap.utils.selector('.REWARDS');

	// 	gsap.timeline()

	// 		.fromTo(
	// 			[q('.TITLE'), q('.BLURB')],
	// 			{ autoAlpha: 0, y: 20 },
	// 			{ immediateRender: true, duration: 1.75, autoAlpha: 1, y: 0, stagger: 0.15, ease: 'expo.out' },
	// 			1
	// 		)
	// 		.timeScale(1.5);
	// }, []);

	return (
		<section
			className={`REWARDS bg-[url('/assets/images/rewards/bg.jpeg')] h-[1000px] w-full bg-cover bg-center relative overflow-hidden leading-0`}
		>
			<div className='flex flex-col-reverse lg:flex-row w-full xl:h-screen md:max-w-[1200px] ml-auto mr-auto'>
				<div className='w-full pt-0 pb-10 pl-10 pr-10 md:pl-10 md:pr-10 md:items-center md:justify-center lg:w-7/12'>
					<figure className='relative lg:-bottom-60 '>
						<Image
							className='xl:max-w-[600px]'
							src={image_app.src}
							width={image_app.width}
							height={image_app.height}
							alt='App'
						/>
					</figure>
				</div>
				<div className='z-50 flex items-start justify-end w-full p-10 xl:mt-20 lg:w-5/12'>
					<div className='text-right'>
						<h1 className='mb-4 text-6xl font-bold leading-none uppercase TITLE font-integral_heavy'>
							<span dangerouslySetInnerHTML={{ __html: t('rewardsTitle') }} />{' '}
						</h1>

						<div className='text-lg font-medium leading-6 tracking-wide text-white BLURB font-trade_gothic_next_condensed'>
							{t(
								'The more. You watch, the more you play, the more you earn gear and gift cards from your favorite brands'
							)}
						</div>
					</div>
				</div>
			</div>

			<figure className='absolute -bottom-[8%] -left-[22%] md:left-[32%] xl:left-[40%] md:right-[10%] md:-bottom-[5%] z-40 max-w-[500px] md:min-w-[700px] xl:min-w-[800px]'>
				<Image src={image_fan.src} width={image_fan.width} height={image_fan.height} alt='Fans' />
			</figure>
		</section>
	);
}
