
import Link from 'next/link';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export const TrackedLink = ({ 
	href,target,className='',onClick,children,event={ event:'Outbound Link Click' }
}) =>
{
	const analytics = useGTMDispatch();
	
	return (
		<Link
			href={href}
			target={target}
			onClick={(e) =>
			{
				const el = e.currentTarget;

				const autoProps = {
					'Click Classes': el.classList.toString(),
					'Click Element': el,
					'Click ID': el.id,
					'Click Target': el.target,
					'Click Text': el.textContent,
					'Click URL': href,
				};
				
				analytics({
					...autoProps,
					...event,
				});
				
				if(onClick)
					onClick(e);
			}}
			className={`TRACKED-LINK ${className}`}
		>
			{children}
		</Link>
	);
}

export default TrackedLink;